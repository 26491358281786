.cursor-pointer {
  cursor: pointer !important;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  flex-shrink: 1;
}

.chat-name {
  font-size: 1.5em;
}

.chat-actions .button {
  margin-left: 10px;
}

.chat-input-container {
  display: flex;
  align-items: center;
  padding: 0px;
  flex-shrink: 0;
}

.chat-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-block-end: 0rem !important;
}

.chat-role {
  gap: 0.5rem !important;
  padding-right: 14px;
}

.paper {
  border-radius: 15px;
  padding: 16px;
}

.human-text {
  color: #737980;
  font-weight: 500;
  font-size: 14px;
}

.content-css {
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.question-wrapper {
  display: flex;
  gap: 0.5rem;
}

.question-label {
  color: "#3F4245";
  font-weight: 600;
  width: 300;
}

.question-text {
  color: "3F4245";
  font-weight: 400;
  width: 300;
}

.chat-role {
  margin-top: 2px !important;
}

.conversation-wrapper {
  overflow-y: auto;
  max-height: calc(95vh - 60px);
  border-radius: 0px;
}

.conversation-history-label {
  font-size: 13px;
  color: gray;
  text-transform: capitalize;
  margin-bottom: 20px !important;
}

.history-label {
  font-weight: 400;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
  text-transform: capitalize;
  font-size: 12px;
}

.canvas-tooltip {
  position: relative;
  font-size: 20px;
  top: 6px;
  color: #868e96;
}

.action-icon {
  border: none !important;
  color: white !important;
  background: var(--primary) !important;
  border-radius: 100% !important;
  width: 20px !important;
}

.menu-description {
  display: block;
  width: 100%;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal !important;
  overflow-wrap: break-word;
  font-size: 12px !important;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-label {
  font-size: 14px !important;
  font-weight: 600;
}

.credential-header {
  display: flex;
  justify-content: space-between;
}

.credential-header-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.credential-name {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center !important;
}

.px-10 {
  padding: 10px 0px !important;
}

.env-card-wrapper {
  width: 100% !important;
  height: 100px !important;
  margin-block-start: 0px !important;
}

.align-items-center {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  align-content: center !important;
}

.credential-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rail-username {
  margin-block-start: 0px !important;
  word-break: break-word !important;
  white-space: pre-wrap !important;
}

.footer {
  position: absolute;
  bottom: 5px !important;
}

.user-menu {
  bottom: 100% !important;
  right: 0% !important;
  transform: translateY(-20px) !important;
}

.user-name-wrapper {
  margin-block-start: 0px !important;
  display: flex !important;
  flex-direction: column !important;
  margin-top: 9px !important;
}

.upload-header {
  font-weight: 500;
}

.upload-content {
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 10px;
}

.no-margin-block-end {
  margin-block-end: 0rem !important;
}

.no-margin-block-start {
  margin-block-start: 0rem !important;
}

.text-12 {
  font-size: 14px !important;
}

.upload-icon {
  font-size: 30px !important;
  color: gray;
}

.textarea-container {
  position: relative;
  width: 100%;
}

.chat-input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 50px 10px 10px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.chat-input::placeholder {
  text-align: left;
  color: #888;
}

.send-button {
  position: absolute;
  right: 0px;
  bottom: 3px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.react-flow__controls {
  background: white !important;
}

.react-flow__controls-button {
  background: transparent !important;
  color: black !important;
  width: auto !important;
  height: auto !important;
}

.react-flow__controls-button svg {
  transform: scale(0.6);
}

/* Ensure container takes some minimum height */
.container {
  padding: 20px;
}

/* Style the scrollable table with a fixed height */
.scrollable-table {
  height: 400px; /* Set the minimum height for the table container */
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* Table styling with BeerCSS */
table {
  width: 100%;
  table-layout: fixed;
}

/* Optional styling for table header */
th {
  position: sticky;
  top: 0;
  background-color: var(--beer-secondary-bg);
  z-index: 2;
}
