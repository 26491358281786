:root,
body {
  --primary: rgb(43 99 139);
  --surface-tint: rgb(43 99 139);
  --on-primary: rgb(255 255 255);
  --primary-container: rgb(204 229 255);
  --on-primary-container: rgb(0 30 49);
  --secondary: rgb(43 99 139);
  --on-secondary: rgb(255 255 255);
  --secondary-container: rgb(204 229 255);
  --on-secondary-container: rgb(0 30 49);
  --tertiary: rgb(15 102 129);
  --on-tertiary: rgb(255 255 255);
  --tertiary-container: rgb(188 233 255);
  --on-tertiary-container: rgb(0 31 41);
  --error: rgb(186 26 26);
  --on-error: rgb(255 255 255);
  --error-container: rgb(255 218 214);
  --on-error-container: rgb(65 0 2);
  --background: rgb(247 249 255);
  --on-background: rgb(24 28 32);
  --surface: rgb(247 249 255);
  --on-surface: rgb(24 28 32);
  --surface-variant: rgb(222 227 235);
  --on-surface-variant: rgb(66 71 78);
  --outline: rgb(114 120 126);
  --outline-variant: rgb(194 199 206);
  --shadow: rgb(0 0 0);
  --scrim: rgb(0 0 0);
  --inverse-surface: rgb(45 49 53);
  --inverse-on-surface: rgb(238 241 246);
  --inverse-primary: rgb(152 204 249);
  --primary-fixed: rgb(204 229 255);
  --on-primary-fixed: rgb(0 30 49);
  --primary-fixed-dim: rgb(152 204 249);
  --on-primary-fixed-variant: rgb(5 75 114);
  --secondary-fixed: rgb(204 229 255);
  --on-secondary-fixed: rgb(0 30 49);
  --secondary-fixed-dim: rgb(152 204 249);
  --on-secondary-fixed-variant: rgb(5 75 114);
  --tertiary-fixed: rgb(188 233 255);
  --on-tertiary-fixed: rgb(0 31 41);
  --tertiary-fixed-dim: rgb(138 208 238);
  --on-tertiary-fixed-variant: rgb(0 77 99);
  --surface-dim: rgb(215 218 223);
  --surface-bright: rgb(247 249 255);
  --surface-container-lowest: rgb(255 255 255);
  --surface-container-low: rgb(241 244 249);
  --surface-container: rgb(235 238 243);
  --surface-container-high: rgb(230 232 238);
  --surface-container-highest: rgb(224 226 232);
  font-family: Roboto;
}

html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  margin: 0;
  background-color: var(--primary-conatiner);
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.metric {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.metric-header {
  background-color: #8884d8;
  color: #fff;
  padding: 15px;
  text-align: center;
  font-size: 18px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

.metric-content {
  padding: 20px;
}

.metric-content .tile {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
  font-size: 24px;
  text-align: center;
}

.metric-content table {
  width: 100%;
  border-collapse: collapse;
}

.metric-content th,
.metric-content td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.metric-content th {
  background-color: #f2f2f2;
} */

.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
  gap: 20px;
  padding: 20px;
}

.chart-container,
.metric {
  background-color: #ffffff; /* Light Grey */
  border-radius: 10px;
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); */
  padding: 20px;
}

.chart-container {
  grid-column: span 2; /* Charts occupy 2 grid columns */
  min-height: 300px; /* Adjust height as needed */
}

.metric {
  min-height: 200px; /* Adjust height as needed */
}

.metric-header {
  background-color: #4e6bff; /* Deep Blue */
  color: #fff;
  padding: 15px;
  text-align: center;
  font-size: 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.metric-content .tile {
  background-color: #f0f0f0; /* Light Grey */
  border-radius: 10px;
  padding: 20px;
  font-size: 24px;
  text-align: center;
}

.metric-content table {
  width: 100%;
  border-collapse: collapse;
}

.metric-content th,
.metric-content td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.metric-content th {
  background-color: #f5f5f5; /* Light Grey */
}
