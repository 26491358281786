/* Container for markdown content */
.custom-markdown {
  /* Optional: Add padding or other container styles */
}

/* Headings */
.custom-markdown h1 {
  font-size: 2em;
  margin: 0.67em 0;
  font-weight: bold;
}

.custom-markdown h2 {
  font-size: 1.5em;
  margin: 0.75em 0;
  font-weight: bold;
}

.custom-markdown h3 {
  font-size: 1.17em;
  margin: 0.83em 0;
  font-weight: bold;
}

.custom-markdown h4 {
  font-size: 1em;
  margin: 1.12em 0;
  font-weight: bold;
}

.custom-markdown h5 {
  font-size: 0.83em;
  margin: 1.5em 0;
  font-weight: bold;
}

.custom-markdown h6 {
  font-size: 0.75em;
  margin: 1.67em 0;
  font-weight: bold;
}

/* Paragraph */
.custom-markdown p {
  margin: 1em 0;
  line-height: 1.5;
  color: inherit;
}

/* Blockquote */
.custom-markdown blockquote {
  margin: 1em 0;
  padding-left: 1em;
  border-left: 4px solid #ccc;
  color: #666;
}

/* Lists */
.custom-markdown ul,
.custom-markdown ol {
  margin: 1em 0;
  padding-left: 2em;
}

.custom-markdown li {
  margin: 0.5em 0;
}

/* Code */
.custom-markdown pre {
  background-color: #f5f5f5;
  padding: 1em;
  overflow: auto;
}

.custom-markdown code {
  font-family: "Courier New", Courier, monospace;
  background-color: #f5f5f5;
  padding: 0.2em 0.4em;
  border-radius: 4px;
}

/* Links */
.custom-markdown a {
  color: #007bff;
  text-decoration: none;
}

.custom-markdown a:hover {
  text-decoration: underline;
}

/* Images */
.custom-markdown img {
  max-width: 100%;
  height: auto;
}

/* Tables */
.custom-markdown table {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
}

.custom-markdown th,
.custom-markdown td {
  border: 1px solid #ddd;
  padding: 0.5em;
  text-align: left;
}

.custom-markdown th {
  background-color: #f5f5f5;
  font-weight: bold;
}
