/* Styling for each chat message */
.message {
  display: flex;
  flex-direction: column;
  /* margin: 10px 0; */
  /* max-width: 70%; */
  padding: 8px;
  /* border: "1px solid #f9f9f9"; */
  width: "fit-content";
  padding: "0 5 5 0";
  margin: "10 0 10 0";
}

/* User message styling (aligns to the right) */
.user {
  background-color: var(--surface-container);
  border-radius: "0 0 0 0" !important;
  place-self: flex-end;
  margin-right: 5px;
}

/* Reply message styling (aligns to the left) */
.reply {
  place-self: flex-start;
  /* border-radius: var(--rounded) var(--rounded) var(--rounded) 0; */
}
